<template>
  <!--个人中心 基本信息 -->
  <div class="dp_f">
    <base-user-info/>
    <div class="basicInformation animate__animated animate__fadeIn">
      <div class="person-info">
        <div class="user-info">
          <div class="user-control">
            <div class="single">
              <div class="user-top">账号角色：</div>
              <div class="user-desc">{{ userInfo.roleName || '暂无角色' }}</div>
            </div>
            <div class="single">
              <div class="user-top">登录账户：</div>
              <div class="user-desc">{{userInfo.account}}</div>
            </div>
            <div class="single">
              <div class="user-top">手机号码：</div>
              <div class="user-desc">{{userInfo.phone || '暂无手机号'}}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 绑定手机号 -->
      <div class="bind-phone" v-show='showBindPhone'>
        <div class="bind-single">
          <span class="red">*</span>手机号码
        </div>
        <div class="bind-input-single">
          <el-input class="bind-input w360" v-model="formData.phone" maxlength="11"
                    placeholder="请输入手机号，后续可采用手机号码登录" v-number
                    v-elInput
                    @blur="formData.phone = $event.target.value"></el-input>
        </div>
        <div class="bind-single mt32">
          <span class="red">*</span>验证码
        </div>
        <div class="bind-input-single">
          <el-input class="bind-input w360" v-model="formData.code" maxlength="6" placeholder="请输入验证码" v-number v-elInput
                    @blur="formData.code = $event.target.value"></el-input>
          <div class="code-btn" :class="['code-btn',isOK?'':'disabled']" @click='sendCode'>{{ str }}</div>
        </div>
        <div class="bind-btn-box">
          <el-button type="primary" class="bind-btn btn1" :disabled='!formData.code || !formData.phone'
                     @click='handleBindPhone'>完成绑定
          </el-button>
        </div>
      </div>
      <!-- 绑定手机号 end-->
      <!-- 修改密码 -->
      <div class="bind-phone" v-show='showChangePwd'>
        <div class="bind-single">
          <span class="red">*</span>新密码
        </div>
        <div class="bind-input-single">
          <el-input :type='formPwd.pwdType' onkeyup="value=value.replace(/[\u4E00-\u9FA5]/g, '');"
                    class="bind-input w360"
                    v-elInput
                    v-model="formPwd.pwd" maxlength="30" placeholder="请输入密码"
                    @blur="formPwd.pwd = $event.target.value">
            <template #suffix>
              <el-icon class="el-input__icon cur_p" @click='changepwdType'>
                <component :is='formPwd.viewPwd'/>
              </el-icon>
            </template>
          </el-input>
        </div>
        <div class="bind-single mt32">
          <span class="red">*</span>确认密码
        </div>
        <div class="bind-input-single">
          <el-input :type='formPwd.pwdType' onkeyup="value=value.replace(/[\u4E00-\u9FA5]/g, '');"
                    class="bind-input w360"
                    v-elInput
                    v-model="formPwd.newPwd" maxlength="30" placeholder="确认密码"
                    @blur="formPwd.newPwd = $event.target.value"></el-input>
        </div>
        <div class="bind-btn-box">
          <el-button type="primary" class="bind-btn btn1" :disabled='!formPwd.pwd && !formPwd.newPwd'
                     @click='changePwd'>完成
          </el-button>
        </div>
      </div>
      <!-- 修改密码 end-->
    </div>
  </div>
</template>
<script setup>
import {getCurrentInstance, markRaw, nextTick, onMounted, reactive, ref} from 'vue'
import {Hide, View} from "@element-plus/icons-vue";
import {codeBtn} from '@/utils/tool/getCode.js'
import {encrypt} from '@/utils/tool'
import {checkPhone, checkPwd} from '@/utils/tool/checkPhone.js'
import {bindPhone, codeSend, updatePwd, userBase} from '@/utils/api/person';
import BaseUserInfo from '@/views/shop/personal/personal/components/userInfo.vue'

const HideCom = markRaw(Hide)
const ViewCom = markRaw(View)
const { proxy } = getCurrentInstance()
const { str , getCode , isOK}  =  codeBtn()
const userInfo = reactive({
  userName:"",
  companyName:"",
  role:"",
  roleName:"",
  account:"",
  phone:"",
  avatar:''
})
const formData = reactive({
  phone:"",
  code:"",
})
const formPwd = reactive({
  pwd:"",
  newPwd:"",
  viewPwd: ViewCom,
  pwdType:'password',
})
const changepwdType = ()=>{
  formPwd.pwdType = formPwd.pwdType === 'password' ? 'text' : 'password'
  formPwd.viewPwd = formPwd.pwdType === 'password' ? ViewCom : HideCom
}
const placeId = 1
const showChangePwd = ref(false)
const showBindPhone = ref(false)
//查看用户基本信息
const getUserInfo = ()=>{
  userBase().then(res=>{
    if (res.code !== 0) {
      proxy.$message.error(res.msg)
      return
    }
    userInfo.userName = res.data.userName || ''
    userInfo.companyName = res.data.companyName || ''
    userInfo.role = res.data.role || ''
    userInfo.roleName = res.data.roleName || ''
    userInfo.account = res.data.account || ''
    userInfo.phone = res.data.phone || ''
    userInfo.pwdStatus = res.data.pwdStatus
    userInfo.avatar = res.data.avatar
    if(!userInfo.phone){
      showBindPhone.value = true
      proxy.$parent.isCheck = true
    }else{
      if(!userInfo.pwdStatus){
        showChangePwd.value = true
        proxy.$parent.isCheck = true
      }else{
         proxy.$parent.isCheck = false
      }
    }
  })
}

//发送验证码
const sendCode = async()=>{
   nextTick(()=>{
  if(!checkPhone(formData.phone)) return
  if(!isOK.value){
    return
  }
  const params = {
    phone:formData.phone,
    placeId,
  }
  codeSend(params).then(res=>{
    if (res.code !== 0) {
      proxy.$message.error(res.msg)
      return
    }
    proxy.$message.success('发送成功')
    getCode()
  })
  })
}
//完成绑定手机号
const handleBindPhone = ()=>{
  if(!checkPhone(formData.phone)) return
  if(!formData.code){
    proxy.$message.error('请输入验证码')
    return
  }
  const params = {
    phone:formData.phone,
    code:formData.code,
    placeId
  }
  bindPhone(params).then(res=>{
    if (res.code !== 0) {
      proxy.$message.error(res.msg)
      return
    }
    proxy.$message.success('绑定成功')
    showBindPhone.value = false
    getUserInfo()
  })
}

const changePwd = ()=>{
  if (formPwd.pwd !== formPwd.newPwd) {
    proxy.$message.error('两次密码不一致')
    return
  }
  if(!checkPwd(formPwd.pwd)) return
  let str = encrypt(formPwd.pwd)
  const params = {
    password:str,
  }
  updatePwd(params).then(res=>{
    if (res.code !== 0) {
      proxy.$message.error(res.msg)
      return
    }
    proxy.$message.success('修改成功')
    showChangePwd.value = false
    getUserInfo()
  })
}
onMounted(()=>{
  getUserInfo()
})
</script>

<style lang="scss" scoped>
@import "./index.scss";
.basicInformation{
  padding-top: 10px;
  flex: auto;
  margin-left: 20px;
  border-radius: 16px;
  background-color: #F7FAFC;
  height: 520px;
  .person-info{
    display: flex;
    align-items: center;
    .user-img{
      width: 80px;
      height: 80px;
      border-radius: 16px;
      overflow: hidden;
      .pic{
        width: 100%;
        height: 100%;
      }
    }
    .user-info{
      margin-left: 25px;
      .user-name{
        display: flex;
        align-items: center;
        >.name{
          font-size: 18px;
          color: $fsColor;
        }
        .company-name{
          display: flex;
          align-items: center;
          margin-left: 10px;
          height: 20px;
          padding: 0 10px;
          background-color: rgba(139, 193, 217,0.1);
          border-radius: 4px;
          .company-icon{
            width: 12px;
            height: 12px;
            margin-right: 5px;
          }
          .name{
            color: #8BC1D9;
            font-size: 12px;
          }
        }
      }
    }
    .user-control{
      margin-top: 14px;
      .single{
        display: flex;
        align-items: center;
        line-height: 18px;
        margin-bottom: 46px;

        &:last-of-type {
          margin-bottom: 40px;
        }
        .user-top{
          color: #737480;
        }
        .user-desc{
          color: $fsColor;
          @include textOverflow(300px);
          line-height: 18px;
        }
      }
    }
  }
}
</style>
