import router from "@/router/index.js";
import {confirmRoute} from "@views/shop/config";
import {ElMessageBox} from "element-plus";
import {useEventListener} from "@/utils/tool/event.js";
import store from '@/store/index.js'

//在独立的js中查询调用这个方法的路由
export function goSomeWhere(route, path, query) {
    if (confirmRoute.indexOf(route.path) !== -1) {
        ElMessageBox.confirm('订单未完成,是否离开？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            customClass: "zh-message-box shop",
        }).then(() => {
            store.dispatch('setShopMealList', [])
            router.push({path: path, query: query})
        }).catch(() => {
            // do nothing
        });
    } else {
        router.push({path: path, query: query})
    }
}

export function listenBack() {
    // history.pushState(null, null, location.href);
    useEventListener(window, 'beforeunload', goBack)
}

function goBack(event) {
    const confirmationMessage = '订单未完成,是否离开？';
    event.preventDefault();
    event.returnValue = confirmationMessage;
    return confirmationMessage;
}