<template>
  <!--个人中心 修改密码 -->
  <div class="changePwd bind-phone animate__animated animate__fadeIn" v-show='!forget'>
      <div class="bind-single">
        登录账户
      </div>
       <div class="bind-input-single">
        <div class="bind-text">{{userInfo.account}}</div>
      </div>
     <div class="bind-single">
        <span class="red">*</span>原设置密码
      </div>
      <div class="bind-input-single">
        <el-input class="bind-input w360" v-model.trim="formData.pwd" v-elInput
          autocomplete="off"
                  onkeyup="value=value.replace(/[\u4E00-\u9FA5]/g, '');" maxlength="30"
                  placeholder="请输入密码"></el-input>
      </div>
      <div class="bind-single mt32">
        <span class="red">*</span>新密码
      </div>
      <div class="bind-input-single">
        <el-input v-elInput class="bind-input w360" :type='formData.pwdType'
        autocomplete="off"
                  onkeyup="value=value.replace(/[\u4E00-\u9FA5]/g, '');" v-model.trim="formData.newPwd"
                  @blur="formData.newPwd = $event.target.value" maxlength="30" placeholder="请输入新密码">
           <template #suffix>
             <el-icon class="el-input__icon cur_p" @click='changePwdType'>
               <component :is='formData.viewPwd'/>
             </el-icon>
          </template>
        </el-input>
      </div>
      <div class="bind-single mt32">
        <span class="red">*</span>确认密码
      </div>
      <div class="bind-input-single">
        <el-input v-elInput class="bind-input w360" onkeyup="value=value.replace(/[\u4E00-\u9FA5]/g, '');"
        autocomplete="off"
        :type='formData.pwdType' v-model.trim="formData.confirmPwd"
                  @blur="formData.confirmPwd = $event.target.value" maxlength="30"
                  placeholder="请再次输入密码确认"></el-input>
      </div>
      <div class="bind-btn-box">
        <el-button  class="bind-btn btn1 zh-btn" @click='forgetPwd'>忘记密码</el-button>
        <el-button type="primary" class="bind-btn btn1" :disabled="!saveStatus" @click='savePwd'>保存</el-button>
      </div>
  </div>
  <div class="changePwd bind-phone animate__animated animate__fadeIn" v-show='forget'>
      <div class="bind-single">
        登录账户
      </div>
       <div class="bind-input-single">
        <div class="bind-text">{{userInfo.account}}</div>
      </div>
     <div class="bind-single">
        <span class="red">*</span>手机号码
      </div>
      <div class="bind-input-single">
        <el-input class="bind-input w360" v-elInput  autocomplete="off" v-model="pwdFormData.phone" disabled maxlength="12" placeholder=""></el-input>
      </div>
       <div class="bind-single mt32">
        <span class="red">*</span>验证码
      </div>
      <div class="bind-input-single">
        <el-input class="bind-input w360" v-elInput  autocomplete="off" v-model="pwdFormData.code" maxlength="6" placeholder="请输入验证码" v-number @blur="pwdFormData.code = $event.target.value"></el-input>
        <div class="code-btn" :class="['code-btn',isOK?'':'disabled']" @click='sendCode'>{{str}}</div>
      </div>
      <div class="bind-single mt32">
        <span class="red">*</span>新密码
      </div>
      <div class="bind-input-single">
        <el-input class="bind-input w360" v-elInput  autocomplete="off" :type='formData.pwdType' v-model="pwdFormData.newPwd" maxlength="30"
                  placeholder="请输入新密码" @blur="pwdFormData.newPwd = $event.target.value">
            <template #suffix>
              <el-icon class="el-input__icon cur_p" @click='changePwdType'>
                <component :is='formData.viewPwd'/>
              </el-icon>
          </template>
        </el-input>
      </div>
      <div class="bind-single mt32">
        <span class="red">*</span>确认密码
      </div>
      <div class="bind-input-single">
        <el-input class="bind-input w360" v-elInput :type='formData.pwdType'  autocomplete="off" v-model="pwdFormData.confirmPwd" maxlength="30"
                  placeholder="请再次输入密码确认" @blur="pwdFormData.confirmPwd = $event.target.value"></el-input>
      </div>
      <div class="bind-btn-box">
        <el-button  class="bind-btn btn1 zh-btn" @click='back'>返回</el-button>
        <el-button type="primary" class="bind-btn btn1" :disabled="!phoneSaveStatus" @click='phoneSavePwd'>保存</el-button>
      </div>
  </div>
</template>

<script setup>
import {computed, getCurrentInstance, markRaw, onMounted, reactive, ref} from 'vue'
import {codeBtn} from '@/utils/tool/getCode.js'
import {checkPhone, checkPwd} from '@/utils/tool/checkPhone.js'
import {encrypt} from '@/utils/tool'
import {codeSend, updatePwd, updatePwdByPhone, userBase} from "@/utils/api/person/index";
import {Hide, View} from "@element-plus/icons-vue";

const HideCom = markRaw(Hide)
const ViewCom = markRaw(View)
const { proxy } = getCurrentInstance()
const { str , getCode , isOK}  =  codeBtn()
const placeId = 3 ;
const forget = ref(false)
const formData = reactive({
    pwd:"", // 原密码
    newPwd:"",  //新密码
    confirmPwd:"", //确认密码
  viewPwd: ViewCom,
    pwdType:'password',
})

const userInfo = reactive({
  userName:"",
  companyName:"",
  role:"",
  roleName:"",
  account:"",
  phone:""
})

//忘记密码
const pwdFormData = reactive({
    phone:"", // 手机号
    code:"",  //验证码
    newPwd:"",  //新密码
    confirmPwd:"" //
})

//查看用户基本信息
const getUserInfo = ()=>{
  userBase().then(res=>{
    if (res.code !== 0) {
      proxy.$message.error(res.msg)
      return
    }
    userInfo.account = res.data.account || ''
    pwdFormData.phone = res.data.phone || ''
  })
}
const changePwdType = () => {
  formData.pwdType = formData.pwdType === 'password' ? 'text' : 'password'
  formData.viewPwd = formData.pwdType === 'password' ? HideCom : ViewCom
}

//忘记密码
const forgetPwd = ()=>{
  forget.value = !forget.value
  resetFormData()
}

//返回
const back =()=>{
  forget.value = !forget.value
  resetPwdFormData()
}

// 重置formData
function resetFormData(){
  formData.pwd = ''
  formData.newPwd = ''
  formData.confirmPwd = ''
  formData.pwdType = 'password'
  formData.viewPwd = View
}

//重置pwdFormData
function resetPwdFormData(){
  pwdFormData.code = ''
  pwdFormData.newPwd = ''
  pwdFormData.confirmPwd = ''
  formData.pwdType = 'password'
  formData.viewPwd = View
}

//修改密码
const savePwd = ()=>{
  if (formData.newPwd !== formData.confirmPwd) {
    proxy.$message.error('两次新密码不一致')
    return
  }
  if(!checkPwd(formData.pwd)) return
  let str = encrypt(formData.pwd)
  let str2 = encrypt(formData.newPwd)
  const params = {
    password:str2,
    originalPassword:str,
  }
  updatePwd(params).then(res=>{
    if (res.code !== 0) {
      proxy.$message.error(res.msg)
      return
    }
    proxy.$message.success('修改成功')
    getUserInfo()
  })
}

//手机号修改密码
const phoneSavePwd = ()=>{
  if (pwdFormData.newPwd !== pwdFormData.confirmPwd) {
    proxy.$message.error('两次新密码不一致')
    return
  }
  let password = encrypt(pwdFormData.newPwd)
  const params = {
    placeId,
    code:pwdFormData.code,
    password:password,
  }
  updatePwdByPhone(params).then(res=>{
    if (res.code !== 0) {
      proxy.$message.error(res.msg)
      return
    }
    proxy.$message.success('修改成功')
    getUserInfo()
  })
}

const saveStatus = computed(()=>{
  return formData.pwd && formData.newPwd && formData.confirmPwd
})

const phoneSaveStatus = computed(()=>{
  return pwdFormData.phone && pwdFormData.code && pwdFormData.newPwd && pwdFormData.confirmPwd
})


//发送验证码
const sendCode = ()=>{
  if(!checkPhone(pwdFormData.phone)) return
  if(!isOK.value){
    return
  }
  getCode()
  const params = {
    phone:pwdFormData.phone,
    placeId,
  }
  codeSend(params).then(res=>{
    if (res.code !== 0) {
      proxy.$message.error(res.msg)
      return
    }
    proxy.$message.success('发送成功')
  })
}
onMounted(()=>{
  getUserInfo()
})
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>