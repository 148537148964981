import {ElMessage} from 'element-plus';

export function checkPhone(phone) {
  if(!phone)  {
    ElMessage .error('手机号不能为空')
    return false
  }
  if(!(/^1[3456789]\d{9}$/.test(phone))){
    ElMessage .error('手机号格式不正确')
    return false
  }
  return true
}

export function checkPwd(pwd) {
  if(!pwd)  {
    ElMessage .error('密码不能为空')
    return false
  }
  //密码长度为6-12位，可以是数组或者字母或者特殊符号
  if (!(/^[0-9A-Za-z]{6,30}$/.test(pwd))) {
    ElMessage.error('密码只能是6-30位的数字或字母')
    return false
  }
  return true
}
