<template>
 <!-- 个人中心 -->
  <div class="zh-main-app">
    <div class="zh-main-content">
      <el-tabs v-model="tabChecked" class="demo-tabs">
        <el-tab-pane label="基本信息"  name="basicInformation"></el-tab-pane>
        <el-tab-pane label="密码修改" :disabled='isCheck' name="changePwd"></el-tab-pane>
        <el-tab-pane label="变更手机号" :disabled='isCheck' name="changPhone"></el-tab-pane>
       </el-tabs>
      <component :is='tabList[tabChecked]'></component>
    </div>
  </div>
</template>

<script setup>
import {ref, shallowRef} from 'vue'

//引入组件
import basicInformation from './components/basicInformation.vue'
import changePwd from './components/changePwd.vue'
import changPhone from './components/changPhone.vue'

const tabChecked = ref('basicInformation')
const tabList = ref({
  basicInformation: shallowRef(basicInformation),
  changePwd: shallowRef(changePwd),
  changPhone: shallowRef(changPhone)
})
const isCheck = ref(false)
defineExpose({
  isCheck
})
</script>

<style lang="scss" scoped>
.zh-main-app{
  min-height: 650px;
}
</style>