<template>
  <!--个人中心 修改手机号 -->
  <div class="basicInformation bind-phone animate__animated animate__fadeIn" v-show='!step'>
    <div class="bind-single mt32">
        <span class="red">*</span>原手机号码
      </div>
      <div class="bind-input-single">
        <el-input v-elInput class="bind-input w360" disabled  v-model.trim="formData.phone"  maxlength="13" ></el-input>
      </div>
      <div class="bind-single mt32">
        <span class="red">*</span>验证码
      </div>
      <div class="bind-input-single">
        <el-input v-elInput class="bind-input w360" v-model="formData.code" maxlength="6" @blur="formData.code = $event.target.value" v-number placeholder="请输入验证码"></el-input>
        <div class="code-btn" :class="['code-btn',isOK?'':'disabled']" @click='sendCode'>{{str}}</div>
      </div>
      <div class="bind-btn-box">
        <el-button  class="bind-btn btn1 zh-btn" @click='next'>下一步</el-button>
      </div>
  </div>
  <div class="basicInformation bind-phone animate__animated animate__fadeIn" v-show='step'>
    <div class="bind-single mt32">
        <span class="red">*</span>新手机号码
      </div>
      <div class="bind-input-single">
        <el-input v-elInput class="bind-input w360"  v-model.trim="newFormData.phone"  maxlength="13" v-number @blur="newFormData.phone = $event.target.value"></el-input>
      </div>
      <div class="bind-single mt32">
        <span class="red">*</span>验证码
      </div>
      <div class="bind-input-single">
        <el-input v-elInput class="bind-input w360" v-model="newFormData.code" maxlength="6" placeholder="请输入验证码" v-number @blur="newFormData.code = $event.target.value"></el-input>
        <div class="code-btn" :class="['code-btn',isOK2?'':'disabled']" @click='sendCodeNew'>{{str2}}</div>
      </div>
      <div class="bind-btn-box">
        <el-button  class="bind-btn btn1 zh-btn" @click='back'>取消</el-button>
        <el-button  class="bind-btn btn1" type="primary" @click='savePhone'>变更完成</el-button>
      </div>
  </div>
</template>

<script setup>
import {getCurrentInstance, onMounted, reactive, ref} from 'vue'
import {codeBtn} from '@/utils/tool/getCode.js'
import {checkPhone} from '@/utils/tool/checkPhone.js'
import {useRouter} from "vue-router";
import {bindPhone, checkPhoneBefore, codeSend, userBase} from '@utils/api/person';

const step = ref(false)
const { proxy } = getCurrentInstance()
const router = useRouter();
const { str , getCode , isOK }  =  codeBtn(60)
const codeBtn2 =  codeBtn(60)
const str2 = codeBtn2.str
const getCode2 = codeBtn2.getCode
const isOK2 = codeBtn2.isOK
const placeId = 2 ;
const formData = reactive({
    phone:"", // 电话
    code:"",  //验证码
})
const newFormData = reactive({
    phone:"", // 电话
    code:"",  //验证码
    phoneNumber:"", //电话id
})
const userInfo = reactive({
  userName:"",
  companyName:"",
  role:"",
  roleName:"",
  account:"",
  phone:""
})

//查看用户基本信息
const getUserInfo = ()=>{
  userBase().then(res=>{
    if (res.code !== 0) {
      proxy.$message.error(res.msg)
      return
    }
    userInfo.account = res.data.account || ''
    formData.phone = res.data.phone || ''
  })
}

//发送验证码
const sendCode = ()=>{
  if(!checkPhone(formData.phone)) return
  if(!isOK.value){
    return
  }
  getCode()
  const params = {
    phone:formData.phone,
    placeId,
  }
  codeSend(params).then(res=>{
    if(res.code != 0){
      proxy.$message.error(res.msg)
      return
    }
    proxy.$message.success('发送成功')
  })
}

const sendCodeNew = ()=>{
  if(!checkPhone(newFormData.phone)) return
  if(!isOK2.value){
    return
  }
  getCode2()
  const params = {
    phone:newFormData.phone,
    placeId:1,
  }
  codeSend(params).then(res=>{
    if(res.code != 0){
      proxy.$message.error(res.msg)
      return
    }
    proxy.$message.success('发送成功')
  })
}

//下一步
const next = ()=>{
  if(!formData.code){
    proxy.$message.error('请输入验证码')
    return
  }
  checkPhoneBefore({placeId,code:formData.code}).then(res=>{
    if(res.code != 0){
      proxy.$message.error(res.msg)
      return
    }
    newFormData.phoneNumber = res.data
    clearFormData()
    step.value = true
  })
}
//返回
const back = ()=>{
  clearNewFormData()
  step.value = false
}

function clearFormData (){
  formData.code = ""
}

function clearNewFormData (){
  newFormData.phone = ""
  newFormData.code = ""
  newFormData.phoneNumber = ""
}

const savePhone = ()=>{
  if(!checkPhone(newFormData.phone)) return
  if(!newFormData.code){
    proxy.$message.error('请输入验证码')
    return
  }
  const params = {
    phone:newFormData.phone,
    phoneNumber:newFormData.phoneNumber,
    code:newFormData.code,
    placeId:1,
  }
  bindPhone(params).then(res=>{
    if(res.code != 0){
      proxy.$message.error(res.msg)
      return
    }
    proxy.$message.success('修改成功')
    proxy.$cookies.remove("token");
    proxy.$cookies.remove("userInfo");
    router.push({
      path: "/login",
    });
  })
}
onMounted(()=>{
  getUserInfo()
})
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>