import {computed} from "vue";
import {useStore} from "vuex";
import {baseUserInfo} from '@utils/api/user.js'
import moment from "moment/moment";
import {setUserInfo} from "@utils/tool/token";
import route from '@/router/index.js';

export default function () {
    const store = useStore()
    const baseInfo = computed(() => store.state.baseic)
    const canUplevel = computed(() => {
        return baseInfo.value.outTime && moment(baseInfo.value.outTime) > new Date().getTime()
    })
    const setBaseInfo = async (cb) => {
        const {data, code} = await baseUserInfo()
        if (code !== 0) {
            return
        }
        if (data.outTime) {
            data.outTime = moment(data.outTime).format('YYYY-MM-DD')
        } else {
            data.outTime = ''
        }
        await store.dispatch('baseic', data)
        setUserInfo(data)
        if (cb) cb(data)
    }
    //跳转购买
    const goBuy = async () => {
        if (baseInfo.value.outTime < new Date().getTime()) {
            //购买新套餐
            await route.push({
                path: "/shop/order/buy/1"
            })
        } else {
            //续费
            await route.push({
                path: "/shop/order/renewal/2"
            })
        }
    }
    return {
        baseInfo,
        canUplevel,
        setBaseInfo,
        goBuy
    }
}